import React from 'react';
import './App.css';

import logo from "./logo.svg";

import { FaDev, FaEnvelope, FaGithub, FaLaptop, FaMapMarker, FaMapPin, FaPaintBrush, FaTwitter } from 'react-icons/fa';

function App() {
  const date = new Date().getFullYear();
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      <div className="card" style={{maxWidth: 600}}>
        <div className={"flex-justify"}>
          <div className={"align-bottom"}>
            <h2>Nitrocade</h2>
            <h1>make games!</h1>
          </div>
          <img src={logo} width={100} height={100} alt="Nitrocade Logo" />
        </div>
        <hr />
        <div className={"r-2col"}>
          <div>
            <p className={"lead"}>We're a group of game developers working on some shiny fun stuff.</p>
            <p>Our team:
            <i>
            <ul>
              <li>Joshua Steele</li>
              <li>Kang Ker Soon</li>
              <li>Samuel Blokland</li>
              <li>Brodie "Ben" Smith</li>
            </ul>
            </i>
            </p>
            <h3>About us</h3>
            <p style={{lineHeight: 2}}>
              <FaMapMarker /> From Adelaide, Australia<br/>
              <FaLaptop /> Mostly programmers<br/>
              <FaPaintBrush /> But also can art!
            </p>
          </div>
          <div>
            <h3>Stay tuned!</h3>
            <p>
             We're currently working on something that we hope you'll like.
             It's early stages but we should have some things to share soon.
            </p>
            <p>
              <strong>Let's keep in touch.</strong>
              <nav>
                <a href="https://twitter.com/nitrocadegames" target="_blank"><FaTwitter /> NitrocadeGames on Twitter</a>
                <a href="https://github.com/nitrocade" target="_blank"><FaGithub /> Nitrocade on GitHub</a>
              </nav>
            </p>
            <h3>Press and enquiries</h3>
            <p>Might take a little while to respond, but bear with us.</p>
            <p className={"lead"}>Shoot us a message!</p>
            <a href="mailto:hello@nitrocade.com?subject=An enquiry"><FaEnvelope /> hello@nitrocade.com</a>
          </div>
        </div>
      </div>
      <p style={{color: "#0011a8", marginBottom: 10}}>&copy; Nitrocade {date}</p>
      <img src={"/logo-word.png"} alt={"Nitrocade Word Logo"} width={150} />
    </div>
  );
}

export default App;
